<template>
  <v-form ref="form">
    <br />
    <div>
      <h5 class="ml-1">Site Update Stat Metrics</h5>
      <div class="small ml-2">
        Each day site and inverter kwh data is updated for 30 days from the
        current day. These metrics will show how many days have not been updated
        for each site and inverter as of today. There is also a refresh button
        that will attempt to update the kwh data for the selected site for the
        previous 30 days. You can also expand the site to view the metrics for
        the inverters for that site.
      </div>
      <br />
    </div>
    <v-btn
      :ripple="false"
      :elevation="0"
      class="font-weight-bold text-xs btn-default bg-gradient-default"
      @click="showMissing()"
      id="update_missing"
    >
      Show Missing</v-btn
    >
    <br />
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="stats"
          :items-per-page="5"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          show-expand
          class="elevation-1"
        >
          <template v-slot:item.nativePlatformLinkIconPlaceholder="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <img
                  :src="
                    require('@/assets/img/' + item.monitoring_system + '.png'
                  )"
                  v-if="item.monitoring_url"
                  v-on="on"
                  v-on:click.stop.prevent="openWindow(item.monitoring_url)"
                />
              </template>
              <span>Monitoring Link</span>
            </v-tooltip>
            <template>
              <v-icon v-if="!item.monitoring_url" disabled>mdi-web-box</v-icon>
            </template>
          </template>
          <template v-slot:item.controls="props">
            <v-btn
              class="mx-2"
              icon
              color="green"
              @click="refreshItem(props.item)"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.missing_dates="{ item }">
            <v-tooltip v-if="item.missing_dates.length > 0" bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="error" v-on="on"> mdi-alert </v-icon>
              </template>
              <span>
                <li v-for="item in item.missing_dates" :key="item">
                  {{ item }}
                </li>
              </span>
            </v-tooltip>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on }"> 0 </template>
              <span>
                <li v-for="item in item.missing_dates" :key="item">
                  {{ item }}
                </li>
              </span>
            </v-tooltip>
          </template>
          <template v-slot:expanded-item="{ item }">
            <td colspan="100%">
              <inverter-missing-update v-bind:site="item">
              </inverter-missing-update>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Service from "@/services/Service.js";
import InverterMissingUpdate from "../inverter/InverterMissingUpdate.vue";

export default {
  components: { InverterMissingUpdate },
  data() {
    return {
      monitorSystem: null,
      props: null,
      toDateMenu: null,
      fromDateMenu: null,
      fromDateVal: null,
      toDateVal: null,
      // items: ['Solaredge', 'Fronius', 'Solarlog', 'Locus', 'Also', 'Enphase'],
      headers: [
        { text: "Site ID", value: "id" },
        { text: "Name", value: "name" },
        {
          text: "Monitoring Link",
          value: "nativePlatformLinkIconPlaceholder",
        },
        { text: "Missing Count", value: "missing_count" },
        { text: "Missing Dates", value: "missing_dates" },
        { text: "Refresh", value: "controls", sortable: false },
      ],
      stats: [],
      expanded: [],
      singleExpand: false,
    };
  },
  beforeMount() {},
  methods: {
    compareTime(time1, time2) {
      return new Date(time1) >= new Date(time2); // true if time1 is later
    },
    showMissing() {
      NProgress.start();
      Service.getMissingUpdateStats()
        .then((response) => {
          this.stats = response.data.data;
          NProgress.done();
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    // reloadAll() {
    //   var startDate = this.fromDateVal;
    //   var endDate = this.toDateVal;
    //   var system = this.monitorSystem.toLowerCase();
    //   const params = {
    //     startDate,
    //     endDate,
    //   };
    //   // check to make sure to is greater than or equal to from
    //   if(!this.compareTime(endDate, startDate)) {
    //     Vue.$toast.error(`Invalid date selection`, {position: "top-right"})
    //     return;
    //   }

    //   console.log(`From Date: ${startDate}, To Date: ${endDate}, System: ${system}`)
    //   NProgress.start()
    //   Service.getSiteDataForProvider(system, params)
    //     .then((response) => {
    //         console.log(response.data)
    //         // this.sites = response.data.sites;
    //         NProgress.done();
    //         Vue.$toast.success(`Data reload queued for ${system}`, {position: "top-right", duration: 4000})
    //     })
    //     .catch((error) => {
    //       NProgress.done();
    //       Vue.$toast.error(`Error reloading data`, {position: "top-right"})
    //       console.error(error)
    //     })
    // },
    openWindow: function (link) {
      window.open(link, "_blank");
    },
    refreshItem(item) {
      console.log(`refresh item ${item.name}`);
      // var startDate = this.fromDateVal;
      // var endDate = this.toDateVal;
      // var system = this.monitorSystem.toLowerCase();
      // const params = {
      //   startDate,
      //   endDate,
      // };
      // // check to make sure to is greater than or equal to from
      // if(!this.compareTime(endDate, startDate)) {
      //   Vue.$toast.error(`Invalid date selection`, {position: "top-right"})
      //   return;
      // }

      // console.log(`From Date: ${startDate}, To Date: ${endDate}, System: ${system}`)
      // console.log(`site id: ${item.id}`)
      Service.reloadMissingSiteStats(item.id)
        .then((response) => {
          Vue.$toast.success(`Data reload queued for site ${item.name}`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    toDateDisp() {
      return this.toDateVal;
    },
  },
};
</script>

<style>
#update_missing {
  margin-left: 0.5%;
}
</style>