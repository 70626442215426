var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "form" },
    [
      _c("br"),
      _c("div", [
        _c("h5", { staticClass: "ml-1" }, [_vm._v("Site Update Stat Metrics")]),
        _c("div", { staticClass: "small ml-2" }, [
          _vm._v(
            " Each day site and inverter kwh data is updated for 30 days from the current day. These metrics will show how many days have not been updated for each site and inverter as of today. There is also a refresh button that will attempt to update the kwh data for the selected site for the previous 30 days. You can also expand the site to view the metrics for the inverters for that site. "
          ),
        ]),
        _c("br"),
      ]),
      _c(
        "v-btn",
        {
          staticClass:
            "font-weight-bold text-xs btn-default bg-gradient-default",
          attrs: { ripple: false, elevation: 0, id: "update_missing" },
          on: {
            click: function ($event) {
              return _vm.showMissing()
            },
          },
        },
        [_vm._v(" Show Missing")]
      ),
      _c("br"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "12" } },
            [
              _c("v-data-table", {
                staticClass: "elevation-1",
                attrs: {
                  headers: _vm.headers,
                  items: _vm.stats,
                  "items-per-page": 5,
                  "single-expand": _vm.singleExpand,
                  expanded: _vm.expanded,
                  "item-key": "id",
                  "show-expand": "",
                },
                on: {
                  "update:expanded": function ($event) {
                    _vm.expanded = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.nativePlatformLinkIconPlaceholder",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { bottom: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      item.monitoring_url
                                        ? _c(
                                            "img",
                                            _vm._g(
                                              {
                                                attrs: {
                                                  src: require("@/assets/img/" +
                                                    item.monitoring_system +
                                                    ".png"),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.openWindow(
                                                      item.monitoring_url
                                                    )
                                                  },
                                                },
                                              },
                                              on
                                            )
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [_c("span", [_vm._v("Monitoring Link")])]
                        ),
                        [
                          !item.monitoring_url
                            ? _c("v-icon", { attrs: { disabled: "" } }, [
                                _vm._v("mdi-web-box"),
                              ])
                            : _vm._e(),
                        ],
                      ]
                    },
                  },
                  {
                    key: "item.controls",
                    fn: function (props) {
                      return [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mx-2",
                            attrs: { icon: "", color: "green" },
                            on: {
                              click: function ($event) {
                                return _vm.refreshItem(props.item)
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-cached")])],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.missing_dates",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.missing_dates.length > 0
                          ? _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              { attrs: { color: "error" } },
                                              on
                                            ),
                                            [_vm._v(" mdi-alert ")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  _vm._l(item.missing_dates, function (item) {
                                    return _c("li", { key: item }, [
                                      _vm._v(" " + _vm._s(item) + " "),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            )
                          : _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        return [_vm._v(" 0 ")]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "span",
                                  _vm._l(item.missing_dates, function (item) {
                                    return _c("li", { key: item }, [
                                      _vm._v(" " + _vm._s(item) + " "),
                                    ])
                                  }),
                                  0
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                  {
                    key: "expanded-item",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "td",
                          { attrs: { colspan: "100%" } },
                          [
                            _c("inverter-missing-update", {
                              attrs: { site: item },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }