var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("quarter-input-number", {
    attrs: { loading: _vm.loading },
    on: { run: _vm.run },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }