var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _vm._m(0),
      _c(
        "v-tabs",
        [
          _c("v-tab", [_vm._v("Missing Site Metrics")]),
          _c("v-tab", [_vm._v("Missing Update Metrics")]),
          _c("v-tab", [_vm._v("Missing Estimates")]),
          _c("v-tab", [_vm._v("Report Groups")]),
          _c("v-tab", [_vm._v("Billing Plans")]),
          _c("v-tab", [_vm._v("Cache")]),
          _c("v-tab", [_vm._v("GMP")]),
          _c(
            "v-tab-item",
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c("br"),
                  _c("div", [
                    _c("h5", { staticClass: "ml-1" }, [
                      _vm._v(
                        " Select Monitoring System and date range for data reload "
                      ),
                    ]),
                    _c("div", { staticClass: "small ml-2" }, [
                      _vm._v(
                        " These metrics will show how many dates are missing (no data collected) for each site and the given date range. This view will allow you to select the given data provider and a date range. Once you click the "
                      ),
                      _c("b", [_vm._v("Show Missing")]),
                      _vm._v(
                        " button, you will see all the dates that are missing for the given site. There is also a refresh button that will attempt to collect the kwh data for the selected site and the given date range. "
                      ),
                    ]),
                  ]),
                  _c("v-select", {
                    staticClass: "ml-2",
                    attrs: {
                      items: _vm.items,
                      label: "Select System",
                      required: "",
                    },
                    model: {
                      value: _vm.monitorSystem,
                      callback: function ($$v) {
                        _vm.monitorSystem = $$v
                      },
                      expression: "monitorSystem",
                    },
                  }),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6", md: "6" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label: "From",
                                              "prepend-icon": "event",
                                              readonly: "",
                                              value: _vm.fromDateDisp,
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.fromDateMenu,
                                callback: function ($$v) {
                                  _vm.fromDateMenu = $$v
                                },
                                expression: "fromDateMenu",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { locale: "en-in", "no-title": "" },
                                on: {
                                  input: function ($event) {
                                    _vm.fromDateMenu = false
                                  },
                                },
                                model: {
                                  value: _vm.fromDateVal,
                                  callback: function ($$v) {
                                    _vm.fromDateVal = $$v
                                  },
                                  expression: "fromDateVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "6", md: "6" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-right": 40,
                                transition: "scale-transition",
                                "offset-y": "",
                                "max-width": "290px",
                                "min-width": "290px",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function (ref) {
                                    var on = ref.on
                                    return [
                                      _c(
                                        "v-text-field",
                                        _vm._g(
                                          {
                                            attrs: {
                                              label: "To",
                                              "prepend-icon": "event",
                                              readonly: "",
                                              value: _vm.toDateDisp,
                                            },
                                          },
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ]),
                              model: {
                                value: _vm.toDateMenu,
                                callback: function ($$v) {
                                  _vm.toDateMenu = $$v
                                },
                                expression: "toDateMenu",
                              },
                            },
                            [
                              _c("v-date-picker", {
                                attrs: { locale: "en-in", "no-title": "" },
                                on: {
                                  input: function ($event) {
                                    _vm.toDateMenu = false
                                  },
                                },
                                model: {
                                  value: _vm.toDateVal,
                                  callback: function ($$v) {
                                    _vm.toDateVal = $$v
                                  },
                                  expression: "toDateVal",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-default bg-gradient-default",
                      attrs: {
                        ripple: false,
                        elevation: 0,
                        id: "metrics_missing",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showMissing()
                        },
                      },
                    },
                    [_vm._v(" Show Missing")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-default bg-gradient-default",
                      attrs: {
                        ripple: false,
                        elevation: 0,
                        id: "metrics_reload",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.reloadAll()
                        },
                      },
                    },
                    [_vm._v(" Reload All")]
                  ),
                  _c("br"),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("v-data-table", {
                            staticClass: "elevation-1",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.sites,
                              "items-per-page": 5,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item.controls",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mx-2",
                                        attrs: { icon: "", color: "green" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.refreshItem(props.item)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("mdi-cached")])],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.missing_site_tof",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.missing_site_tof
                                            ? "true"
                                            : "false"
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.missing_inverter_tof",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          item.missing_inverter_tof
                                            ? "true"
                                            : "false"
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-tab-item", [_c("failed-update")], 1),
          _c("v-tab-item", [_c("missing-estimate")], 1),
          _c("v-tab-item", [_c("reporting-group")], 1),
          _c("v-tab-item", [_c("billing-plan")], 1),
          _c(
            "v-tab-item",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0 },
                  on: {
                    click: function ($event) {
                      return _vm.resetCache()
                    },
                  },
                },
                [_vm._v(" Reset Cache")]
              ),
            ],
            1
          ),
          _c("v-tab-item", [_c("gmp-custom-report")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h1", [_vm._v("Data Management")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }