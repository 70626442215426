<template>
  <div>
    <br />
    <h3 class="ml-1">Reporting Groups</h3>
    <Grid
      ref="grid"
      :style="{ width: '560px' }"
      :data-items="result"
      :filterable="true"
      :filter="filter"
      @filterchange="filterChange"
      :edit-field="'inEdit'"
      :sortable="{ mode: 'multiple' }"
      :sort="sort"
      @sortchange="sortChangeHandler"
      :pageable="pageable"
      :take="take"
      :skip="skip"
      :total="total"
      @itemchange="itemChange"
      @dataStateChange="dataStateChange"
      :columns="columns"
      @pagechange="pageChange"
    >
      <template v-slot:myTemplate="{ props }">
        <custom
          :data-item="props.dataItem"
          @edit="edit"
          @save="save"
          @remove="remove"
          @cancel="cancel"
        />
      </template>
      <grid-toolbar>
        <v-btn
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-default"
          @click="insert"
          id="report_add"
        >
          Add new
        </v-btn>
        <v-btn
          v-if="hasItemsInEdit"
          :ripple="false"
          :elevation="0"
          class="font-weight-bold text-xs btn-default bg-gradient-danger"
          @click="cancelChanges"
          id="report_cancel"
        >
          Cancel current changes
        </v-btn>
      </grid-toolbar>
      <grid-norecords> There is no data available custom </grid-norecords>
    </Grid>
  </div>
</template>

<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import { orderBy } from "@progress/kendo-data-query";
import { filterBy } from "@progress/kendo-data-query";
import CommandCell from "./CommandCell";

import Service from "@/services/Service.js";
Vue.use(VueToast);

export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    custom: CommandCell,
  },
  data: function () {
    return {
      filter: null,
      sort: null,
      pageable: { pageSizes: [5, 10, 50, 100] },
      gridData: [],
      skip: 0,
      take: 10,
      pageSize: 10,
      updatedData: [],
      editID: null,
      sort: [{ field: "name", dir: "asc" }],
      columns: [
        { field: "id", editable: false, title: "ID", width: "100px" },
        { field: "name", width: "250px" },
        { cell: "myTemplate", filterable: false, width: "210px" },
      ],
    };
  },
  computed: {
    result: {
      get: function () {
        return filterBy(this.gridData, this.filter).slice(
          this.skip,
          this.take + this.skip
        );
      },
    },
    hasItemsInEdit() {
      return this.gridData.filter((p) => p.inEdit).length > 0;
    },
    total() {
      return this.gridData ? filterBy(this.gridData, this.filter).length : 0;
    },
  },
  created: function () {
    this.getData();
  },
  methods: {
    filterChange: function (ev) {
      this.filter = ev.filter;
    },
    getData: function () {
      Service.getReportingGroups()
        .then((response) => {
          this.gridData = response.data;
        })
        .catch((error) => {
          Vue.$toast.error(`Error loading data`, { position: "top-right" });
          console.log(error);
        });
    },
    sortChangeHandler: function (e) {
      this.sort = e.sort;
      this.gridData = orderBy(this.gridData, this.sort);
    },
    dataStateChange: function (event) {
      console.log("dataStateChange...");
    },

    pageChange(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    itemChange: function (e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign({}, this.gridData[index], {
          [e.field]: e.value,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        e.dataItem[e.field] = e.value;
      }
    },
    insert() {
      const dataItem = { inEdit: true };
      const newData = this.gridData.slice();
      newData.unshift(dataItem);
      this.update(newData, dataItem);
      this.gridData = newData;
    },
    edit: function (e) {
      let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
      let updated = Object.assign({}, this.gridData[index], { inEdit: true });
      this.gridData.splice(index, 1, updated);
    },
    save: function (e) {
      let id = e.dataItem.id;
      let index = this.gridData.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(e.dataItem) ||
          (id && c.id === id)
      );
      let item = this.gridData[index];
      let updated = Object.assign(this.update(this.gridData.slice(), item), {
        inEdit: undefined,
      });
      // upsert logic... base off id.. if 0, then create... else update
      if (updated.id == undefined) {
        Service.createReportingGroup(updated)
          .then((response) => {
            Vue.$toast.success(`Group created`, {
              position: "top-right",
              duration: 4000,
            });
            updated.id = response.data.id;

            this.gridData.splice(index, 1, updated);
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      } else {
        Service.updateReportingGroup(updated.id, updated)
          .then((response) => {
            Vue.$toast.success(`Group updated`, {
              position: "top-right",
              duration: 4000,
            });
            this.gridData.splice(index, 1, updated);
          })
          .catch((error) => {
            Vue.$toast.error(`Error saving data`, { position: "top-right" });
            console.log(error);
          });
      }
    },
    update(data, item, remove) {
      let updated;
      let id = item.id;
      let index = data.findIndex(
        (c) =>
          JSON.stringify({ ...c }) === JSON.stringify(item) ||
          (id && c.id === id)
      );
      if (index >= 0) {
        updated = Object.assign({}, item);
        data[index] = updated;
      } else {
        let id = 0;
        updated = Object.assign({}, item, { id: id });
        data.unshift(updated);
        index = 0;
      }

      if (remove) {
        data = data.splice(index, 1);
      }
      return data[index];
    },
    cancel(e) {
      if (e.dataItem.id) {
        let index = this.gridData.findIndex((p) => p.id === e.dataItem.id);
        let updated = Object.assign(this.gridData[index], {
          inEdit: undefined,
        });
        this.gridData.splice(index, 1, updated);
      } else {
        let index = this.gridData.findIndex(
          (p) => JSON.stringify(e.dataItem) === JSON.stringify(p)
        );

        this.gridData.splice(index, 1);
      }
    },
    remove(e) {
      e.dataItem.inEdit = undefined;
      Service.deleteReportingGroup(e.dataItem.id)
        .then((response) => {
          Vue.$toast.warning(`Group removed`, {
            position: "top-right",
            duration: 4000,
          });
          this.update(this.gridData, e.dataItem, true);
        })
        .catch((error) => {
          Vue.$toast.error(`Error deleting data`, { position: "top-right" });
          console.log(error);
        });
    },
    cancelChanges() {
      let editedItems = this.gridData.filter((p) => p.inEdit === true);
      if (editedItems.length) {
        editedItems.forEach((item) => {
          item.inEdit = undefined;
        });
      }
    },
  },
};
</script>

<style>
#report_add {
  margin-left:0.5%;
}
#report_cancel {
  margin-left: 1%;
}
</style>