var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("br"),
      _c("h3", { staticClass: "ml-1" }, [_vm._v("Billing Plans")]),
      _c(
        "Grid",
        {
          ref: "grid",
          style: { width: "460px" },
          attrs: {
            "data-items": _vm.result,
            filterable: true,
            filter: _vm.filter,
            "edit-field": "inEdit",
            sortable: { mode: "multiple" },
            sort: _vm.sort,
            pageable: _vm.pageable,
            take: _vm.take,
            skip: _vm.skip,
            total: _vm.total,
            columns: _vm.columns,
          },
          on: {
            filterchange: _vm.filterChange,
            sortchange: _vm.sortChangeHandler,
            itemchange: _vm.itemChange,
            dataStateChange: _vm.dataStateChange,
            pagechange: _vm.pageChange,
          },
          scopedSlots: _vm._u([
            {
              key: "myTemplate",
              fn: function (ref) {
                var props = ref.props
                return [
                  _c("custom", {
                    attrs: { "data-item": props.dataItem },
                    on: {
                      edit: _vm.edit,
                      save: _vm.save,
                      remove: _vm.remove,
                      cancel: _vm.cancel,
                    },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "grid-toolbar",
            [
              _c(
                "v-btn",
                {
                  staticClass:
                    "font-weight-bold text-xs btn-default bg-gradient-default",
                  attrs: { ripple: false, elevation: 0, id: "billing_add" },
                  on: { click: _vm.insert },
                },
                [_vm._v(" Add new ")]
              ),
              _vm.hasItemsInEdit
                ? _c(
                    "v-btn",
                    {
                      staticClass:
                        "font-weight-bold text-xs btn-default bg-gradient-danger",
                      attrs: {
                        ripple: false,
                        elevation: 0,
                        id: "billing_cancel",
                      },
                      on: { click: _vm.cancelChanges },
                    },
                    [_vm._v(" Cancel current changes ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("grid-norecords", [_vm._v(" There is no data available custom ")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }