<template>
  <quarter-input-number :loading="loading" @run="run"></quarter-input-number>
</template>

<script>
import Service from "@/services/Service.js";
import QuarterInput from './reports/QuarterInput.vue'
import QuarterInputNumber from './reports/QuarterInputNumber.vue';
export default {
  components: { QuarterInput, QuarterInputNumber },
  props: {
  },
  computed: {
    
  },
  created: function () {
    
  },
  data: function () {
    return {
      loading: false,
    };
  },
  methods: {
    
    run(e) {
      console.log(`run: quarter: ${e.quarter}, year: ${e.year}`);
      this.loading = true;
      Service.downloadGmpCustomReport(e.quarter, e.year)
        .then((response) => {
          this.handleZipDownload(response);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);    
        });
    },
    handleZipDownload(response) {
      // const url = window.URL.createObjectURL(new Blob([response.data]));
      // const link = document.createElement('a');
      // link.href = url;
      // link.setAttribute('download', 'report.zip');
      // document.body.appendChild(link);
      // link.click();

      const blob = new Blob([response.data], { type: 'application/zip' })
      const link = document.createElement('a')
      let filename = "gmp-sites.zip"
      link.href = URL.createObjectURL(blob)
      link.download = filename
      link.click()
      URL.revokeObjectURL(link.href)
    },
  },
  watch: {
    
  },
};
</script>

<style>

</style>